import { Outlet, Route, Routes } from 'react-router-dom';
import "./Home.css";
import Loader from "./Loader.tsx";
import Dashboard from "./dashboard/Dashboard.tsx";
import { useAuthStore } from "./store/AuthStore.tsx";
import ModalTokenExpired from "./dashboard/ModalTokenExpired.tsx";
import NavBar from './shared/NavBar.tsx';
import { useEffect } from "react";
import { eventGa4Service } from "./ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "./shared/analytics.tsx";

function Home() {
    const tokenExpired = useAuthStore((state) => state.accessTokenExpired);
    const getMe = useAuthStore((state) => state.getMe);

    useEffect(() => {
        getMe().then(() => {
            const isFirstViewDashboard = localStorage.getItem("isFirstViewDashboard");
            if (isFirstViewDashboard && isFirstViewDashboard === "true") {
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.HOME.LOGIN_SUCCESSFUL.ACTION,
                });
                localStorage.setItem("isFirstViewDashboard", "false");
            }
        });
    }, []);

    return (
        <main className="min-h-screen bg-white w-full">
            <Loader/>
            <NavBar customCssStyle="border-b"/>
            <div className="db__container">
                <Routes>
                    <Route index element={ <Dashboard/> }/>
                </Routes>
                <Outlet/>
            </div>

            { tokenExpired && <ModalTokenExpired showModal={ true }/> }
        </main>
    );
}

export default Home;
