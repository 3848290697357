import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import FullTable from "@/components/ui/full-table";
import Tabs from "./components/tabs";
import useTableColumns from "./hooks/useTableColumns";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Modal, { ModalState } from "@/shared/Modal";
import { useDashboardStore } from "@/store/DashboardStore";
import { descendingComparator } from "../helpers";

interface ModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    data: any;
}

export const viewReceipts = (data: any) => {

    const { setIsModalDetractionOpen, setRowDetractionSelected } = useDashboardStore(state => state);

    const handleClick = (data: any) => {
        setRowDetractionSelected(data)
        setIsModalDetractionOpen(ModalState.OPEN)
        return 0
      }
    return (
        <div className="db__card--link cursor-pointer flex gap-2 items-center mt-2" onClick={()=> {handleClick(data)}} >
            <span>Ver Comprobante</span>
            <img src="/assets/ic_arrow_right.svg" alt="Ir" />
        </div>
    )
}

const ReportModal = ({ modalState, setModalState, data }: ModalProps) => {
    return (
        <Modal modalState={modalState} typeModal="editReport" showAlertIcon={false} modalWidth="w-3/5" onClose={()=> setModalState(ModalState.CLOSE)}>
            <div className="w-full py-3">
                <div className="text-center">
                    <h2 className="text-xl leading-7 font-medium mb-4">Constacia de deposito</h2> 
                </div>
                <div className="text-left font-semibold text-sm">
                    <label><span className="font-semibold mr-2">Número de constancia</span>  {data?.account_number}</label> <br/>
                    <label><span className="font-semibold mr-2">Usuario SOL</span>  {data?.user || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">N° de cuenta de detracciones</span>  {data?.account_number_detraction || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">Tipo de cuenta</span>  {data?.account_type || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">RUC del proveedor</span>  {data?.customer_identity_number}</label> <br/>
                    <label><span className="font-semibold mr-2">Nombre/ Razón Social del Proveedor</span>  {data?.supplier_business_name}</label> <br/>
                    <label><span className="font-semibold mr-2">Tipo de Operación</span>  {data?.operation_name}</label> <br/>
                    <label><span className="font-semibold mr-2">Tipo de bien o servicio</span>  {data?.operation_type || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">Monto del depósito</span>  {data?.deposit_amount}</label> <br/>
                    <label><span className="font-semibold mr-2">Fecha y hora de pago</span>  {data?.payment_datetime}</label> <br/>
                    <label><span className="font-semibold mr-2">Periodo tributario</span>  {data?.tax_period_payment}</label> <br/>
                    <label><span className="font-semibold mr-2">Tipo de comprobante</span>  {data?.type_certificate || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">Número de comrpobante</span>  {data?.certificate_number || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">Número de operación</span>  {data?.operation_number || '-'}</label> <br/>
                    <label><span className="font-semibold mr-2">Número de pago de detracciones</span>  {data?.detraction_payment || '-'}</label> <br/>
                </div>
            </div>
        </Modal>
    );
};

function Detractions() {

  const [currentTab, setCurrentTab] = useState("S");
  const columns = useTableColumns(currentTab);
  const { filters ,fetchDetractionsReport, detractionsReport, 
    valuesFilterSelected, setValuesFilterSelected, isModalDetractionOpen, 
    setIsModalDetractionOpen, rowDetractionSelected } = useDashboardStore(state => state)

  const handleSelect = (value: string) => {
    setValuesFilterSelected({
        ...valuesFilterSelected,
        tax_period: value,
    });
  }

  useEffect(() => {
    const paid = currentTab
    fetchDetractionsReport(paid, '', '')
    .catch((error) => {
        console.log('Error:', error)
    })
  }, [currentTab, valuesFilterSelected]);

  return (
    <div>
      <Link to="/inicio" className="flex items-center text-neutral-normal gap-1">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="text-sm f-secondary">Volver a listado de empresas</span>
      </Link>
      <h1 className="font-bold text-2xl mt-6 mb-8">Facturas afectas a detracción</h1>
      <div className="flex justify-between items-center">
        <Tabs selectedTab={currentTab} onClick={(value) => setCurrentTab(value)} />

        <Select value={valuesFilterSelected.tax_period} onValueChange={ handleSelect }>
          <SelectTrigger className="max-w-28">
            <SelectValue placeholder="Período" />
          </SelectTrigger>
          <SelectContent>
            
            <SelectGroup>
            {filters.tax_periods?.sort(descendingComparator).map((period: string, i: number) => (
                    <SelectItem value={period} key={`opt-period-${i}`}>
                        {period}
                    </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {detractionsReport && (
          <>
          
          <FullTable columns={columns} data={detractionsReport.records} />
          {/* <Pagination className="justify-end my-5">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink isActive>1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationNext />
              </PaginationItem>
            </PaginationContent>
          </Pagination> */}
          <ReportModal modalState={isModalDetractionOpen} 
            setModalState={setIsModalDetractionOpen} 
            closeModalCallback= {() => ModalState.CLOSE}
            data = {rowDetractionSelected}/>
        </>
      )}
    </div>
  );
}

export default Detractions;
