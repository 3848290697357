import { create } from "zustand";

interface AppicationState {
    isLoading: boolean;
    showAccountantProfileSOLForm: boolean;
    loading: (loading: boolean) => void;
    setShowAccountantProfileSOLForm: (showing: boolean) => void;
}

export const useApplicationStore = create<AppicationState>((set) => ({
    isLoading: false,
    showAccountantProfileSOLForm: true,
    loading: (loading: boolean) => set({ isLoading: loading }),
    setShowAccountantProfileSOLForm: (showing: boolean) => set({ showAccountantProfileSOLForm: showing }),
}));
