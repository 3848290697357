import { create } from "zustand";
import { useApplicationStore } from "./ApplicationStore";
import { useAuthStore } from "./AuthStore";
import axios from "axios";
import { createJSONStorage, persist } from "zustand/middleware";
import { ModalState } from "@/shared/Modal";
import { toast } from "@/hooks/use-toast";
import { ToastAction } from "@radix-ui/react-toast";

const LOCAL_API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "http://localhost:3000";

interface ReportsSelected {
    code: string;
    reason: string;
}
interface DashboardState {
    accountant: Accountant;
    filters: Filters;
    valuesFilterSelected: ValuesSelected;
    dashboardData: DashboardData | null;
    reportsObserved: ReportsObserved | null;
    ReportsRejected: ReportsObserved | null
    reportsPurchaseRetentions: ReportsRetention | null;
    reportsPurchasePerceptions: ReportPerceptions | null;
    detractionsReport: any | null; // TODO agregar tipado
    rowDetractionSelected: any | null;
    isModalDetractionOpen: ModalState;
    setValuesFilterSelected: (values: any) => void;
    setReportsObserved: (reportsObserved: ReportsObserved) => void;
    setReportsPerceptions: (reportsPerceptions: ReportPerceptions) => void;
    setDetractionsReport: (detractionsReport: any[]) => void; // TODO agregar tipado
    validateReportObserved: (reportsSelected: ReportsSelected[]) => Promise<void>;
    declineReportObserved: (reportsSelected: ReportsSelected[]) => Promise<void>;
    editCredentials: (body: any, uuid: string) => void;
    fetchFilters: (abortSignal?: AbortSignal) => Promise<null | Filters>;
    fetchDashboardData: () => Promise<void>;
    fetchDashboardReportsObserved: () => Promise<void>;
    fetchReportRetentions: () => Promise<void>;
    fetchReportPerceptions: () => Promise<void>;
    fetchDetractionsReport: (paid: boolean|string, page: number|string, page_record: number|string) => Promise<void>;
    fetchDashboardReportsRejected: () => Promise<void>;
    resetFilters: () => void;
    resetReportsObserved: () => void;
    resetReportsPerceptions: () => void;
    resetDashboardData: () => void;
    setIsModalDetractionOpen: (values: any) => void;
    setRowDetractionSelected: (values: any) => void;
}

function handleErrorResponse(error: unknown, setAccessTokenExpired: (expired: boolean) => void): void {
    if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
            case 401:
                setAccessTokenExpired(true);
                throw new Response(error.message, { status: 401, statusText: error.message });
            case 404:
                throw new Response(error.message, { status: 404, statusText: error.message });
            default:
                throw new Response(error.message, { status: 500, statusText: error.message });
        }
    } else {
        throw new Response('Unexpected error occurred', { status: 500 });
    }
}

export const useDashboardStore = create<DashboardState>()(
    persist(
        // type: ignore
        // @ts-ignore
        (set, get) => ({
            accountant: {
                name: "",
            },
            filters: {
                tax_periods: [],
                businesses: [],
            },
            valuesFilterSelected: {
                ruc: "",
                tax_period: "",
                paid: true,
                page: 1,
                page_record: 2
            },
            rowDetractionSelected: null,
            dashboardData: null,
            reportsObserved: null,
            reportsPurchasePerceptions: null,
            detractionsReport: null,
            isModalDetractionOpen: ModalState.CLOSE,
            setValuesFilterSelected(valuesFilterSelected) {
                set({ valuesFilterSelected });
            },
            setReportsObserved(reportsObserved: ReportsObserved) {
                set({ reportsObserved });
            },
            setReportsPerceptions(reportsPerceptions: ReportPerceptions) {
                set({ reportsPurchasePerceptions: reportsPerceptions })
            },
            setDetractionsReport(detractionsReport: any[]) {
                set({ detractionsReport });
            },
            setIsModalDetractionOpen(isModalDetractionOpen: any){
                set({ isModalDetractionOpen })
            },
            setRowDetractionSelected(rowDetractionSelected) {
                set({rowDetractionSelected})
            },
            async validateReportObserved(reportsSelected: ReportsSelected[]) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed/validate/?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            cpes: reportsSelected
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }

                return Promise.resolve();
            },
            async editCredentials(body: any, uuid: string) {
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();
                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/${uuid}/credentials`,
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        },
                    ).then(() => {
                        toast({
                              description: "Credenciales actualizadas correctamente!",
                              action: (
                                <ToastAction altText="x">x</ToastAction>
                              ),
                            })
                    })
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }
            },
            async declineReportObserved(reportsSelected: ReportsSelected[]) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed/decline/?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            cpes: reportsSelected
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        },
                    );
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }

                return Promise.resolve();
            },
            async fetchFilters(abortSignal?: AbortSignal) {
                useApplicationStore.getState().loading(true);

                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                let promiseResponse: Filters | null = null;

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/accountant/dashboard/filters`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            signal: abortSignal,
                        },
                    );

                    const descendingComparator = (a: string, b: string) => {
                        if (a > b) {
                            return -1;
                        }

                        if (a < b) {
                            return 1;
                        }

                        return 0;
                    };

                    let { tax_periods, businesses } = response.data;

                    tax_periods = response.data.tax_periods?.sort(descendingComparator);

                    setAccessTokenExpired(false);
                    set({ filters: { tax_periods, businesses } });
                    promiseResponse = { tax_periods, businesses };
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }

                return Promise.resolve(promiseResponse);
            },
            async fetchDashboardData() {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();
                
                const filters = {
                    ruc,
                    tax_period,
                };

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/accountant/dashboard?filters=${JSON.stringify(filters)}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    const dashboardData = response.data;
                    
                    setAccessTokenExpired(false);
                    set({ dashboardData });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async fetchDashboardReportsObserved() {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    set({
                        reportsObserved: {
                            ...response.data,
                            cpes: response.data?.cpes.map((cpes: Cpes) => ({
                                ...cpes,
                                checked: false
                            }))
                        }
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async fetchDashboardReportsRejected() {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/rejected?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    set({
                        ReportsRejected: {
                            ...response.data,
                            cpes: response.data?.cpes.map((cpes: Cpes) => ({
                                ...cpes,
                                checked: false
                            }))
                        }
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async fetchReportPerceptions() {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/perceptions?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    set({
                        reportsPurchasePerceptions: response.data
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async fetchReportRetentions() {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/retentions?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    set({
                        reportsPurchaseRetentions: response.data
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async fetchDetractionsReport(paid, page, page_record) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().loading(true);
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/detractions?ruc=${ruc}&tax_period=${tax_period}&page=${page}&page_record=${page_record}&paid=${paid}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    if (response.data) {
                        set({
                            detractionsReport: response.data
                        });
                    } else {
                        throw new Error("No data found");
                    }
                } catch(error) {
                    handleErrorResponse(error, setAccessTokenExpired);
                } finally {
                    useApplicationStore.getState().loading(false);
                }
                return Promise.resolve();
            },
            async resetFilters() {
                set({
                    filters: {
                        tax_periods: [],
                        businesses: [],
                    },
                    valuesFilterSelected: {
                        ruc: "",
                        tax_period: "",
                    },
                });
            },
            async resetReportsObserved() {
                set({
                    reportsObserved: null
                });
            },
            async resetReportsPerceptions() {
                set({
                    reportsPurchasePerceptions: null
                });
            },
            async resetDashboardData() {
                set({
                    dashboardData: null
                });
            }
        }),
        {
            name: "dashboard-store",
            storage: createJSONStorage(() => sessionStorage),
            version: 2,
            partialize: (state) => ({ filters: state.filters, valuesFilterSelected: state.valuesFilterSelected }),
        },
    ),
);
