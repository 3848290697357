import { Link } from 'react-router-dom';
import { useDashboardStore } from "../../store/DashboardStore";
import { descendingComparator, formatNumber } from "../helpers";
import React, { useEffect, useState } from "react";
import Card from "../../shared/CardInfo";
import TablePerceptions from "./TablePerceptions.tsx";
import icLeftGray from "/assets/ic_angle_left_gray.svg?url";

interface CardsHeaderProps {
    total_amount_perceptions: number;
    total_count: number;
}

const CardsHeader = ({ total_amount_perceptions, total_count}: CardsHeaderProps) => {
    const dataCards = [
        {
            ccsClass: 'bill-obs__card--1',
            label: 'Total de percepciones',
            amount: null,
            total: total_count
        },
        {
            ccsClass: 'bill-obs__card--2',
            label: 'Monto total de percepciones',
            amount: total_amount_perceptions,
            total: null
        },
    ];

    return (
        <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-xl">
            {dataCards.map((data, index) => (
                <Card key={`card-bill-observed-${index}`} className={`${data.ccsClass} h-full flex-[1_1_100%]`}>
                    <Card.Body>
                        <p className="font-semibold text-sm f-secondary mb-2">{data.label}</p>

                        {data.amount || data.amount === 0 ? (
                            <p className="text-xl f-secondary font-normal f-secondary">s/ {formatNumber(data.amount)}</p>
                        ) : (
                            <p className="text-xl f-secondary font-normal f-secondary">{data.total}</p>
                        )}
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

const FilterPeriod = (
    {   filters,
        selectedPeriod,
        setSelectedPeriod
    }: {
        filters: Filters,
        selectedPeriod: string;
        setSelectedPeriod: (period: string) => void;
    }) => {
    const valuesSelected = useDashboardStore((state) => state.valuesFilterSelected);
    const setValuesFilterSelected = useDashboardStore((state) => state.setValuesFilterSelected);
    const fetchDashboardReportsPerceptions = useDashboardStore((state) =>state.fetchReportPerceptions);

    const fetchDashboardReports = () => {
        fetchDashboardReportsPerceptions()
            .then(() => console.info("Dashboard reports perceptions fetched"))
            .catch((_) => console.error("Dashboard reports perceptions couldn't fetch"));
    };
    const handleTaxPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValuesFilterSelected({
            ...valuesSelected,
            tax_period: e.target.value,
        });

        setSelectedPeriod(e.target.value);
        fetchDashboardReports();
    };

    return (
        <div className="relative min-w-40 w-full">
            <select
                onChange={handleTaxPeriodChange}
                value={selectedPeriod}
                id="period"
                className="business__select w-full text-sm appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none">
                {filters.tax_periods?.sort(descendingComparator).map((period: string, i: number) => (
                    <option value={period} key={`opt-period-${i}`}>
                        {period}
                    </option>
                ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
                </svg>
            </div>
        </div>
    );
};

function Perceptions() {
    const { filters, reportsPurchasePerceptions, fetchReportPerceptions } = useDashboardStore((state) => state);

    useEffect(() => {
        fetchReportPerceptions()
            .then(() => console.info("Dashboard reports perceptions fetched"))
            .catch((_) => console.error("Dashboard reports perceptions couldn't fetch"));
    }, []);

    const [selectedPeriod, setSelectedPeriod] = useState('');
    return (
        <>
            <Link to="/inicio" className="flex items-center gap-1">
                <img src={icLeftGray} alt="Volver"/>
                <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
            </Link>
            <div className="sm:flex items-center gap-3 justify-between mt-4 lg:mt-6">
                <h2 className="bill-obs-detail__title">Percepciones</h2>
                <div className="min-[360px]:flex gap-4 justify-center align-middle mt-4 sm:mt-0">
                    <FilterPeriod
                        filters={filters}
                        selectedPeriod={selectedPeriod}
                        setSelectedPeriod={setSelectedPeriod}
                    />
                    {/*
                    // TODO: Implement download TXT
                    <button
                        type="button"
                        className="flex-none bg-primary-black rounded-[40px] px-5 py-2 font-medium text-sm text-white"
                    >Descargar TXT</button>*/}
                </div>
            </div>

            {
                reportsPurchasePerceptions &&
                <CardsHeader
                    total_count={reportsPurchasePerceptions.total_count}
                    total_amount_perceptions={reportsPurchasePerceptions.total_amount_perceptions}
                />
            }

            {
                reportsPurchasePerceptions?.cpes && reportsPurchasePerceptions?.cpes.length > 0
                    ? <TablePerceptions cpesPerceptions={reportsPurchasePerceptions.cpes}/>
                    : <p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
            }   
        </>
    );
}

export default Perceptions;
