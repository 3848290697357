import icCardApproved from "/assets/ic_card_approved_black.svg?url";
import arrowRightBlue from "/assets/ic_arrow_right_blue.svg?url";
import icCardDenied from "/assets/ic_card_denied_black.svg?url";
import { formatNumber } from "../helpers";
import Card from "@/shared/CardInfo";

export default function DetractionsCard(/** receive data */) {
  return (
    <Card>
     <Card.Body>
      <p className="text-primary-black font-semibold text-lg mb-2">Detracciones</p>
        <div className="sm:flex gap-4 lg:gap-16">
          <div className="flex gap-4 items-center">
            <div className="w-14 h-14 flex items-center justify-center rounded-full bg-salmon">
              <img src={icCardDenied} alt="Icono tarjeta" />
            </div>

            <div>
              <p className="text-primary-black f-secondary text-lg font-semibold">Por pagar</p>
              <p className="f-secondary text-2xl text--primary-black">s/ {formatNumber(0)}</p>
              <p className="text-neutral-normal f-secondary text-sm">Importe total</p>
            </div>
          </div>

          <div className="flex gap-4 items-center mt-4 sm:mt-0">
            <div className="w-14 h-14 flex items-center justify-center rounded-full bg-turquoise">
              <img src={icCardApproved} alt="Icono tarjeta" />
            </div>

            <div>
              <p className="text-primary-black f-secondary text-lg font-semibold">Pagadas</p>
              <p className="f-secondary text-2xl text--primary-black">s/ {formatNumber(0)}</p>
              <p className="text-neutral-norma f-secondary text-sm">Importe total</p>
            </div>
          </div>
        </div>

        <div
          className="flex mt-2 items-center gap-1 text-sm text-primary-blue-2 font-bold f-secondary cursor-pointer"
          onClick={() => {}}>
          Ver detalle <img src={arrowRightBlue} alt="->" />
        </div>
     </Card.Body>
    </Card>
  );
}
