import { Outlet, Navigate } from "react-router-dom";
import { useAuthStore } from "./store/AuthStore";
import { useEffect } from 'react';

export const PrivateRoutes = () => {
    const userToken = useAuthStore((state) => state.accessToken);
    const getMe = useAuthStore((state) => state.getMe);

    useEffect(() => {
        if (userToken) {
            getMe().catch((_) => console.error("Error getting user data..."));
        }
    }, [userToken, getMe]);

    return userToken ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoutes = () => {
    const userToken = useAuthStore((state) => state.accessToken);
    return userToken ? <Navigate to="/inicio" /> : <Outlet />;
};
