import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { eventGa4Service } from '../ga4.service.tsx';
import "./SidebarDashboard.css";
import { getInitials, menuListItems } from '../dashboard/helpers.ts';

interface BusinessInfo {
	ruc: string;
	businessName: string;
}

function NavbarDesktop({ stateLocation }: { stateLocation: BusinessInfo }) {
	const [_, setActiveIndex] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();

	const [ , , currentPath] = location.pathname.split('/');

	const handleItemClick = (index: number, item: MenuItem) => {
		if (item.tagAnalytics !== '') {
			eventGa4Service({
				action: item.tagAnalytics,
			});
		}
		setActiveIndex(index);
		navigate(item.path, {
			state: {
				page: item.path,
				...stateLocation
			},
		});
	};

	return (
		<div className="navbar__links--desktop hidden lg:block" id="step-one">
			{menuListItems.map((item, index) => (
				<div
					key={ item.title }
					className={ `sidebar__item--d ${
						currentPath === item.path || item.subpaths?.includes(location.pathname)
							? 'active'
							: ''
					} rounded-[40px]` }
					onClick={ () => handleItemClick(index, item) }>
					<img
						className="flex-none"
						src={ `/assets/ic_${
							currentPath === item.path || item.subpaths?.includes(location.pathname)
								? item.icon + '--active'
								: item.icon
						}.svg` }
						alt={ item.title }
					/>
					<span>{ item.title }</span>
				</div>
			)) }
		</div>
	);
}

function SideBarDashboard() {
	const location = useLocation();

	return (
		<div
			className="sidebar__container hidden lg:flex lg:flex-col lg:justify-start flex-none lg:px-5 lg:py-6 lg:min-h-screen lg:max-h-screen">
			{
				location.state?.businessName && (
					<div className="flex items-center gap-3 mb-6">
						<div
							className="w-10 h-10 p-4 rounded-[58px] bg-[#A7BFFC] flex justify-center items-center">
							<p className="font-medium text-sm f-secondary text-[#1840A5]">
								{ getInitials(location.state?.businessName) }
							</p>
						</div>
						<p className="font-semibold text-sm">
							<span className="inline-block f-secondary text-sm font-semibold text-[#15161C] -mb-1">{ location.state?.businessName }</span>
							<span className="inline-block f-secondary text-[10px] font-normal text-[#15161C]">RUC { location.state?.ruc }</span>
						</p>
					</div>
				)}
			<NavbarDesktop stateLocation={ location.state }/>
		</div>
	);
}

export default SideBarDashboard;
