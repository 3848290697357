import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "./components/CustomInput";
import { useAuthStore } from "../store/AuthStore";
import ErrorAlert from "../errors/ErrorAlert";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';
import { registerAccountant } from "../services/customer-io.ts";

const URL_TYC = 'https://www.somoscontapro.com/terminos-y-condiciones';
const URL_PRIVACY = 'https://www.somoscontapro.com/politicas-de-privacidad';
function trimObjectValues(obj: { [key: string]: any }): User {
    const trimmedObject: { [key: string]: any } = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            trimmedObject[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
        }
    }

    return trimmedObject as User;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese su nombre"),
    last_name: Yup.string().required("Ingrese sus apellidos"),
    document_number: Yup.string()
        .required("Ingrese su documento de identidad")
        .matches(/^\d{8}$/, "Ingrese un DNI válido"),
    email: Yup.string().required("Ingrese su correo electrónico").email("Ingrese un correo electrónico válido"),
    phone_number: Yup.string()
        .required("Ingrese su número de celular")
        .matches(/^[9]\d{8}$/, "Ingrese un número telefónico válido"),
    quantity_business: Yup.number()
        .typeError("La cantidad debe de ser numérico")
        .required("Ingrese la cantidad de Pymes"),
    accountant_profile: Yup.string().required("Seleccione su perfil de contador"),
    password: Yup.string()
        .min(6, "La contraseña debe de tener como mínimo 6 caracteres")
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, {
            message: "La contraseña debe contener al menos una mayúscula y una minúscula",
            excludeEmptyString: true,
        })
        .required("Ingrese su contraseña"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Su contraseña no coincide")
        .required("Vuelva a ingresar su contraseña"),
    terms: Yup.boolean().oneOf([true], ""),
    privacy: Yup.boolean().oneOf([true], ""),
});

function RegisterAccountantForm() {
    const errorMessages: ErrorMessage[] = useAuthStore((state) => state.errorMessages);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<User>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    });

    const setUser = useAuthStore((state) => state.setNewUser);
    const createAccount = useAuthStore((state) => state.createUser);

    const onSubmit = async (data: User) => {
        const credentials = import.meta.env.VITE_CUSTOMERIO_CREDENTIALS;
        if (isSubmitting) return;

        const cleanData: User = trimObjectValues(data);

        setUser({ ...cleanData });

        try {
            await createAccount()
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_REGISTER.ACTION
            });
            if (credentials) {
                await registerAccountant(cleanData);
            }
            localStorage.setItem("isFirstLogin", "true");
            localStorage.setItem("isFirstViewDashboard", "true");
            navigate("/registrar-empresas");
        } catch (error) {
            console.error("Error register account...", error)
        }            
    };

    const handleClickLogin = () => {
        eventGa4Service({
            action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_LOGIN.ACTION
        });
    };

    return (
        <div className="max-w-[488px] px-4 mx-auto my-auto lg:p-0">
            <ErrorAlert errorMessages={errorMessages} />

            <form
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
            >
                <h1 className="text-2xl font-bold pb-3">Regístrate y empieza a ahorrar tiempo</h1>

                <div className="flex w-full gap-1">
                    <CustomInput<User>
                        id="name"
                        labelInput="Nombres"
                        type="text"
                        placeholder="Ingrese sus nombres"
                        register={register}
                        errorMessage={errors.name?.message}
                    />

                    <CustomInput<User>
                        id="last_name"
                        labelInput="Apellidos"
                        type="text"
                        placeholder="Ingrese sus apellidos"
                        register={register}
                        errorMessage={errors.last_name?.message}
                    />
                </div>

                <div className="flex w-full gap-1">
                    <CustomInput<User>
                        id="document_number"
                        labelInput="DNI"
                        type="text"
                        placeholder="Ej. 70897878"
                        register={register}
                        errorMessage={errors.document_number?.message}
                    />

                    <CustomInput<User>
                        id="phone_number"
                        labelInput="N° celular"
                        type="text"
                        placeholder="Ej. 989898978"
                        register={register}
                        errorMessage={errors.phone_number?.message}
                    />
                </div>

                <CustomInput<User>
                    id="email"
                    labelInput="Correo electrónico"
                    type="text"
                    placeholder="Ingrese su correo electrónico"
                    register={register}
                    errorMessage={errors.email?.message}
                />

                <div className="md:flex w-full gap-1">
                    <CustomInput<User>
                        id="quantity_business"
                        labelInput="Cantidad de empresas"
                        type="text"
                        placeholder="Ingrese la cantidad de empresas"
                        register={register}
                        errorMessage={errors.quantity_business?.message}
                    />

                    <div className="flex-[0_1_50%] my-2">

                        <select
                            defaultValue=""
                            {...register("accountant_profile")}
                            id="countries"
                            className="input-app text-[#4D5B70] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none shadow-none">
                            <option value="">Escoge un perfil</option>
                            <option value="Contador Freelance">Contador Freelance</option>
                            <option value="Estudio Contable">Estudio Contable</option>
                        </select>
                    </div>
                </div>

                <CustomInput<User>
                    id="password"
                    labelInput="Contraseña"
                    type="password"
                    placeholder="Ingrese su contraseña"
                    register={register}
                    errorMessage={errors.password?.message}
                />

                <CustomInput<User>
                    id="confirm_password"
                    labelInput="Valide su contraseña"
                    type="password"
                    placeholder="Ingrese nuevamente su contraseña"
                    register={register}
                    errorMessage={errors.confirm_password?.message}
                />

                <div className="flex align-middle pt-2">
                    <label className="w-full cursor-pointer" htmlFor="terms">
                        <input type="checkbox" id="terms" {...register("terms")} />
                        <span className="pl-1 text-xs">Acepto los </span>
                        <a href={URL_TYC} target="_blank" className="link text-xs">
                            términos y condiciones
                        </a>
                    </label>
                </div>

                <div className="flex align-middle pt-2">
                    <label className="w-full cursor-pointer" htmlFor="privacy">
                        <input type="checkbox" id="privacy" {...register("privacy")} />
                        <span className="pl-1 text-xs">Acepto las </span>
                        <a href={URL_PRIVACY} target="_blank" className="link text-xs">
                            politícas de privacidad
                        </a>
                    </label>
                </div>

                <div className="input-flex pt-4">
                    <button type="submit" className="primary-button-2 py-2 px-4">
                        Crear cuenta
                    </button>
                </div>
            </form>

            <Link
                onClick={() => handleClickLogin()}
                to="/login" className="font-semibold f-secondary text-sm inline-block mt-5 text-[#1B68FF] text-center"
            >
                ¿Ya tienes cuenta? Inicia sesión
            </Link>
        </div>
    );
}

export default RegisterAccountantForm;
