import { cn } from "@/lib/utils"

interface TabsProps {
    onClick(tabValue: string): void;
    selectedTab: string;
    tab?: tab[]
}
interface tab {
    label: string;
    value: string;
}

const tabs = [
    {
        label: "Pagadas",
        value: "S"
    },
    {
        label: "Por pagar",
        value: "N"
    },
]


export default function Tabs({ selectedTab, onClick, tab}: TabsProps) {
    tab? tab: tab=tabs
    return <ul className="flex max-w-fit border-b border-neutral-lightest my-4">
        {tab.map(({ value, label }) => (
            <li key={value} onClick={() => onClick(value)} className={cn("py-3 px-6 border-b-2 cursor-pointer font-semibold text-neutral-normal", selectedTab === value && 'border-b-blue-normal text-black')}>{label}</li>
        ))}
    </ul>
}