import { create } from "zustand";
import { useApplicationStore } from "./ApplicationStore";
import { useAuthStore } from "./AuthStore";
import axios from "axios";
import { AxiosError } from "axios";

const EMPTY_BUSINESS = {
    uuid: "",
    name: "",
    ruc: "",
    terms: false,
    privacy: false,
    registered: false,
};

export enum BusinessCreationStatus {
    CREATED = "CREATED",
    NOT_CREATED = "NOT_CREATED",
    ERROR = "ERROR",
}

interface BusinessState {
    business: Business;
    profileSOL: ProfileSOL;
    businessCreatedSuccessFully: BusinessCreationStatus;
    errorMessages: ErrorMessage[];
    setBusiness: (business: Business) => void;
    setProfileSOL: (profileSOL: ProfileSOL) => void;
    createBusiness: () => Promise<void>;
    setBusinessCreatedSuccessFully: (status: BusinessCreationStatus) => void;
}

const LOCAL_API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "http://localhost:3000";

export const useBusinessStore = create<BusinessState>((set, get) => ({
    business: EMPTY_BUSINESS,
    profileSOL: {
        username: "",
        passwordSOL: "",
    },
    businessCreatedSuccessFully: BusinessCreationStatus.NOT_CREATED,
    errorMessages: [],
    setBusiness(business: Business) {
        set({ errorMessages: [] });
        set({ business: business });
    },
    setProfileSOL(profileSOL: ProfileSOL) {
        set({ errorMessages: [] });
        set({ profileSOL });
    },
    async createBusiness() {
        useApplicationStore.getState().loading(true);

        const business = get().business;
        const profileSOL = get().profileSOL;
        const accessToken = useAuthStore.getState().accessToken;

        set({ errorMessages: [] });

        try {
            const response = await axios.post(
                `${LOCAL_API_BASE_URL}/app/internal/me/businesses`,
                {
                    ruc: business.ruc,
                    name: business.name,
                    username: profileSOL.username,
                    passwordSOL: profileSOL.passwordSOL,
                    terms: business.terms,
                    privacy: business.privacy,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            if (response.status === 200 || response.status === 204) {
                set({ businessCreatedSuccessFully: BusinessCreationStatus.CREATED });
                return Promise.resolve();
            } else {
                set({ businessCreatedSuccessFully: BusinessCreationStatus.ERROR });
                return Promise.reject(new Error(`Error: ${response.statusText}`));
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const { setAccessTokenExpired } = useAuthStore.getState();
                const axiosError = error as AxiosError<any>;

                if (error.response?.status === 401) {
                    setAccessTokenExpired(true);
                    throw new Response(error as any, {
                        status: 401,
                        statusText: error.message,
                    });
                }

                set({ businessCreatedSuccessFully: BusinessCreationStatus.ERROR });
                set({ errorMessages: [...get().errorMessages, axiosError.response?.data.message] });

                // Throwing 404 error
                throw new Response(error as any, { status: error.response?.status });
            } else {
                set({ businessCreatedSuccessFully: BusinessCreationStatus.ERROR });
                set({ errorMessages: [...get().errorMessages, String(error)] });

                // Throw 500 error
                throw new Response(error as any, { status: 500 });
            }
        } finally {
            set({ business: EMPTY_BUSINESS, profileSOL: { username: "", passwordSOL: "" } });
            useApplicationStore.getState().loading(false);
        }

        return Promise.resolve();
    },
    setBusinessCreatedSuccessFully(status) {
        set({ businessCreatedSuccessFully: status });
    },
}));
