import { TableColumn } from "@/components/ui/full-table";
import { formatNumber } from "@/dashboard/helpers";
import { viewReceipts } from "../Detractions";

interface DetractionsTableColumn extends TableColumn {
    status?: string
}

export const columns: DetractionsTableColumn[] = [
  {
    label: "Serie - Número",
    key: "serie_number",
    // render: ({ voucherSerie, voucherNumber }) => `${voucherSerie} - ${voucherNumber}`,
  },
  {
    label: "Nombre/Razón social del proveedor",
    key: "supplier_business_name",
  },
  {
    label: "Total de factura",
    key: "total_amount",
    render: ({ total_amount }) => `${formatNumber(total_amount)}`,
  },
  {
    label: "Fecha de vencimiento",
    key: "issuer_date",
    status: "to_pay"
  },
  {
    label: "Fecha de depósito",
    key: "payment_datetime",
    status: "paid",
  },
  {
    label: "N° de constancia",
    key: "account_number",
    status: "paid",
  },
  {
    label: "Tipo de operación",
    key: "operation_name",
    status: "paid",
  },
  {
    label: "Representación",
    key: "",
    status: "paid",
    render: (row) => viewReceipts(row)
  }
];

/**
 * The idea of having this columns as a hook was having access to the status for dynamic columns
 * however this status is not yet provide by API and Sunat portal so instead the columns will be exported
 * as a regular constant array. When this data is available uncomment this function and implement it
 *
*/

 export default function useTableColumns(status: string) {
   return columns.filter(column => {
       return (!column.status || column.status === status)
   })
 }