import { useApplicationStore } from "./store/ApplicationStore";
import "./Loader.css";
import spinner from "/assets/spinner.svg?url";

export default function Loader() {
    const isLoading = useApplicationStore((state) => state.isLoading);

    return (
        isLoading && (
            <div className="loader-container">
                <img className="spinner" src={spinner} alt="spinner" />
            </div>
        )
    );
}
