import { useDashboardStore } from '../store/DashboardStore.tsx';
import { descendingComparator } from './helpers.ts';

const FilterByPeriod = (
	{   filters,
		selectedPeriod,
		setSelectedPeriod,
		fetchDataForTable
	}: {
		filters: Filters,
		selectedPeriod: string;
		setSelectedPeriod: (period: string) => void;
		fetchDataForTable: () => void;
	}) => {
	const valuesSelected = useDashboardStore((state) => state.valuesFilterSelected);
	const setValuesFilterSelected = useDashboardStore((state) => state.setValuesFilterSelected);

	const handleTaxPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setValuesFilterSelected({
			...valuesSelected,
			tax_period: e.target.value,
		});

		setSelectedPeriod(e.target.value);
		fetchDataForTable();
	};

	return (
		<div className="relative min-w-32">
			<select
				onChange={handleTaxPeriodChange}
				value={selectedPeriod}
				id="period"
				className="business__select w-full text-sm appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none">
				{filters.tax_periods?.sort(descendingComparator).map((period: string, i: number) => (
					<option value={period} key={`opt-period-${i}`}>
						{period}
					</option>
				))}
			</select>
			<div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
				<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
					<path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
				</svg>
			</div>
		</div>
	);
};

export default FilterByPeriod;
