import React, { ChangeEvent, useEffect } from "react";
import { ReactNode, useState } from "react";
import Modal, { ModalState } from "../shared/Modal";
import tooltipIcon from "/assets/ic_question_circle.svg?url";
import exclamationIcon from "/assets/ic_exclamation_triangle.svg?url";
import angleLeftIcon from "/assets/ic_angle_left.svg?url";
import { formatNumber } from "./helpers";
import { useDashboardStore } from "../store/DashboardStore";
import "./TableByDiscrimination.css";
import Joyride, { CallBackProps, Step } from "react-joyride";
import TourTooltip from "../shared/TourTooltip";

interface ShowItemsModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    cpes: Cpes | null;
}

interface AprobeReportModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    cpes: Cpes;
}

interface TableRowProps {
    cpes: Cpes;
    reportsObserved: ReportsObserved;
    setModalOpenShowItems: (newState: ModalState) => void;
    setSelectedCpes: (cpes: Cpes) => void;
}

interface TooltipProps {
    text: string;
    children: ReactNode;
}

export interface TableColumn {
    label: string;
    className?: string;
    tooltip?: string;
}
  
interface TableHeaderProps {
    columns: TableColumn[];
    cpes: Cpes[];
}

interface TextAreaProps {
    maxLength: number;
    valueTextArea: string;
    setValueTextArea: (value: string) => void;
}

interface EditReportFormProps {
    cpes: Cpes;
    setModalState: (newState: ModalState) => void;
    setIsButtonValidateClicked?: (state: boolean) => void;
}

const TextArea: React.FC<TextAreaProps> = ({ maxLength, valueTextArea, setValueTextArea }) => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const inputText: string = event.target.value;
        if (inputText.length <= maxLength) {
            setValueTextArea(inputText);
        }
    };
  
    return (
        <div>
            <textarea
                placeholder="Mi empresa si tiene auto, etc."
                className="border border-[#868F9E] w-full rounded-lg h-44 px-3 py-2 text-sm"
                value={valueTextArea}
                onChange={handleChange}
                maxLength={maxLength}
            />
            <div className="text-right text-xs">
                Caracteres restantes: {maxLength - valueTextArea.length}/{maxLength}
            </div>
        </div>
    );
};
  
export const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
    return (
        <thead className="header-table">
            <tr>
                {columns.map((column, index) => (
                    <th key={index} className={`header-table_th header-t-obs_th-${index + 1} text-left tracking-wider ${column.className ?? ''}`}>
                        <div className="flex">
                            {
                                column.tooltip ? (
                                    <>
                                        <p className="f-secondary font-medium text-[13px]">{ column.label }</p>
                                        <Tooltip text={column.tooltip}>
                                            <img src={tooltipIcon} alt="Somos Contapro" />
                                        </Tooltip>
                                    </>
                                    ) : (
                                        <p className="f-secondary font-medium text-[13px]">{ column.label }</p>
                                    )
                            }
                        </div>
                    </th>
                ))}
            </tr>
      </thead>
    );
};

const ShowItemsModal = ({ modalState, setModalState, closeModalCallback, cpes }: ShowItemsModalProps) => {
    const [ isButtonValidateClicked, setIsButtonValidateClicked ] = useState(false);
    const onAprobeReport = () => {
        setIsButtonValidateClicked(true);
    }

    useEffect(() => {
        setIsButtonValidateClicked(false);
    }, [ closeModalCallback ]);


    const Items = () => {
        return (
            <>
                <h2 className="text-xl leading-7 font-medium mb-4">Items Comprobante #{cpes?.number}</h2>
                <div className="p-4">
                    <ul className="list-disc">
                        {
                            cpes?.items?.map((item, index) => (
                                <li key={'item' + index}>{item.description}</li>
                            ))
                        }
                    </ul>
                </div>
                
                {
                    cpes?.observation &&
                    <>
                        <hr />
                        <div className="mt-4">
                            <p className="text-sm leading-5 font-semibold">Observación</p>
                            <p className="text-sm leading-5 font-normal mt-2">{cpes?.observation}</p>
                        </div>
                    </>
                }

                <div className="w-full text-right mt-4">
                    <button
                        className="bg-primary-black rounded-[20px] px-3 py-2 font-medium font-xs text-white"
                        onClick={() => onAprobeReport()}
                    >Validar factura</button>
                </div>
            </>
        )
    }

    return (
        <Modal modalState={modalState} typeModal="editReport" modalWidth="w-5/6" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="w-full">
                {
                    !isButtonValidateClicked
                        ? <Items />
                        : (
                            <div>
                                <div
                                    className="flex gap-2 mb-4 cursor-pointer"
                                    onClick={() => setIsButtonValidateClicked(false)}
                                >
                                    <img className="inline-block" src={angleLeftIcon} alt="Volver" />
                                    <p className="text-sm font-medium text-[#5D6075]">Volver</p>
                                </div>

                                <div className="text-left">
                                    <h2 className="text-xl leading-7 font-medium mb-4">Validar comprobante #{cpes?.number}</h2>
                                </div>
                                {
                                    cpes &&
                                    <EditReportForm cpes={cpes} setModalState={setModalState} setIsButtonValidateClicked={setIsButtonValidateClicked}></EditReportForm>
                                }
                            </div>
                        )
                }
            </div>
        </Modal>
    );
};

const EditReportForm = ({ cpes, setModalState, setIsButtonValidateClicked }: EditReportFormProps) => {
    const [text, setText] = useState<string>('');
    const { validateReportObserved } = useDashboardStore(state => state);

    const onClickValidateReport = () => {
        setModalState(ModalState.LOADING);

        const body = [
            {
                code: cpes.code,
                reason: text
            }
        ];

        validateReportObserved(body)
            .then(() => {

                setModalState(ModalState.DONE);
                if (setIsButtonValidateClicked) {
                    setIsButtonValidateClicked(false);
                }
                console.log("Aprobe report success")
            })
            .catch((_) => {
                setModalState(ModalState.ERROR);
                console.error("Aprobe report error")
            });
    }

    return (
        <div className="text-left">
            <h3 className="text-sm font-semibold mb-1">Observación</h3>
            <p className="text-sm font-normal">{cpes.observation}</p>
            <hr className="my-3"/>
            <h3 className="text-sm font-semibold mb-3">Indícanos la correción de la observación</h3>
            <div>
                <TextArea
                    maxLength={100}
                    valueTextArea={text}
                    setValueTextArea={setText}
                ></TextArea>
            </div>

            <div className="text-right mt-3">
                <button
                    disabled={!text || text.trim().length === 0}
                    className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[200px] px-3 py-2 bg-stone-950 text-white"
                    onClick={() => onClickValidateReport()}
                >Corregir factura</button>
            </div>
        </div>
    );
}

// cuando selecciona solo uno
const ValidateReportModal = ({ modalState, setModalState, closeModalCallback, cpes }: AprobeReportModalProps) => {
    return (
        <Modal modalState={modalState} typeModal="editReport" showAlertIcon={false} modalWidth="w-3/5" onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="text-left">
                    <h2 className="text-xl leading-7 font-medium mb-4">Validar comprobante #{cpes?.number}</h2>
                </div>
                {
                    cpes &&
                    <EditReportForm cpes={cpes} setModalState={setModalState}></EditReportForm>
                }          
            </div>
        </Modal>
    );
};

const DeclineReportModal = ({ modalState, setModalState, closeModalCallback, cpes }: AprobeReportModalProps) => {
    const { declineReportObserved } = useDashboardStore(state => state);

    const onClickDeclineReport = () => {
        setModalState(ModalState.LOADING);

        const body = [
            {
                code: cpes?.code,
                reason: ''
            }
        ]

        declineReportObserved(body)
            .then(() => {
                console.log("Correct report success");
                setModalState(ModalState.DONE);
            })
            .catch((_) => {
                setModalState(ModalState.ERROR);
                console.error("Correct report error")
            });
    }

    return (
        <Modal modalState={modalState} typeModal="aprobeReport" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="text-center">
                    <img className="inline-block" src={exclamationIcon} alt="Alerta" />
                    <h2 className="text-xl my-2 leading-7 font-medium">¿Estás de acuerdo con la observación?</h2>
                </div>
                <div className="">
                    <p className="text-sm mb-3">
                    El comprobante ya no se contará como deducible, y no se sumará para el cálculo de compras
                    del periodo. No podrás deshacer esta opción.
                    </p>
                    <div className="sm:flex gap-3 items-center justify-center">
                        <button
                            className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[76px] px-3 py-2 bg-stone-950 text-white"
                            onClick={() => onClickDeclineReport()}
                        >Aprobar</button>
                        <button
                            className="flex-none rounded-[20px] w-full sm:w-[76px] px-3 py-2 border border-stone-950"
                            onClick={() => closeModalCallback()}
                        >Volver</button>
                    </div>
                </div>           
            </div>
        </Modal>
    );
};

const Tooltip = ({ text, children }: TooltipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const formattedText = text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return (
        <div className="pl-2 relative flex items-center" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <div className="inline-block">{children}</div>
            {showTooltip && (
                <div className="absolute top-6 -left-40 z-10 p-2 text-xs font-normal leading-tight text-white bg-slate-800 rounded whitespace-no-wrap tooltip w-64">
                    {formattedText}
                    <div className="absolute w-3 h-3 bg-slate-800 transform rotate-45 -top-1 left-1/2"></div>
                </div>
            )}
        </div>
    );
    
};

const TableRow = ({ cpes, reportsObserved, setModalOpenShowItems, setSelectedCpes }: TableRowProps) => {
    const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(ModalState.CLOSE);
    const [isModalOpenDeclineReport, setModalOpenDeclineReport] = useState<ModalState>(ModalState.CLOSE);
    const [isChecked, setIsChecked] = useState(cpes.checked);
    const { setReportsObserved, fetchDashboardReportsObserved } = useDashboardStore(state => state);
 
    const onClickShowItems = () => {
        setSelectedCpes(cpes); 
        setModalOpenShowItems(ModalState.OPEN);
    };

    const formatDate = (date: string): string => {
        var parts = date.split('-');
        return parts[2] + '/' + parts[1] + '/' + parts[0];
    }

    const validateReport = () => {
        setModalOpenValidateReport(ModalState.OPEN);
    }

    const declineReport = () => {
        setModalOpenDeclineReport(ModalState.OPEN);
    }

    const fetchDashboardReports = () => {
        fetchDashboardReportsObserved()
            .then(() => console.info("Reports observed fetched"))
            .catch((_) => {
                console.error("Reports observed data couldn't fetch");
        });
    }

    const handleOnClickCloseModalDecline = () => {
        setModalOpenDeclineReport(ModalState.CLOSE);
        fetchDashboardReports();
    }

    const handleOnClickCloseModalValidate = () => {
        setModalOpenValidateReport(ModalState.CLOSE);
        fetchDashboardReports();
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, cpesToUpdate: Cpes) => {
        const updatedCpes = reportsObserved.cpes.map(cpes => {
            if (cpes.code === cpesToUpdate.code) {
                setIsChecked(event.target.checked);
                return { ...cpes, checked: event.target.checked };
            }
            return cpes;
        });
    
        setReportsObserved({
            ...reportsObserved,
            cpes: updatedCpes
        });
    };

    useEffect(() => {
        setIsChecked(cpes.checked);
    }, [cpes.checked]);

    return (
        <>
            <tr className="bg-white border-b h-14">
                <td className="body_td-obs text-center">
                    <input
                        type="checkbox"
                        className="w-5 h-5 checked:accent-slate-900"
                        checked={ isChecked }
                        onChange={ (event) => handleCheckboxChange(event, cpes) }
                    />
                </td>

                <td className="body_td-obs f-secondary">
                    { formatDate(cpes.issue_date) }
                </td>
                <td className="body_td-obs f-secondary">
                    { cpes.serie } - { cpes.number }
                </td>
                <td className="body_td-obs f-secondary">
                    { cpes.issuer_business_name }
                </td>
                <td className="body_td-obs f-secondary">
                    s/ { formatNumber(cpes.igv_amount) }
                </td>
                <td className="body_td-obs f-secondary">
                    { cpes.observation }
                </td>
                <td className="body_td-obs">
                    <div className="actions flex gap-3 max-w-fit">
                        <button
                            className="bg-success-light shrink-0 w-[76px] rounded-[20px] px-3 py-2 font-medium font-xs text-success-dark"
                            onClick={ () => validateReport() }
                        >
                                Validar
                        </button>
                        <ValidateReportModal
                            modalState={ isModalOpenValidateReport }
                            setModalState={ setModalOpenValidateReport }
                            closeModalCallback={ () => handleOnClickCloseModalValidate() }
                            cpes={ cpes }
                        />
                        <button
                            className="bg-error-light shrink-0 rounded-[20px] px-3 py-2 font- font-xs text-error-dark"
                            onClick={ () => declineReport() }
                        >
                            Rechazar factura
                        </button>
                        <DeclineReportModal
                            modalState={ isModalOpenDeclineReport }
                            setModalState={ setModalOpenDeclineReport }
                            closeModalCallback={ () => handleOnClickCloseModalDecline() }
                            cpes={ cpes }
                        />
                    </div>
                </td>
                <td className="body_td-obs text-center">
                    {
                        cpes.items
                            ? <span className="item_link font-bold" onClick={ () => onClickShowItems() }>Ver items</span>
                            : <span className="text-gray-400 font-semibold">Ver items</span>
                    }
                </td>
            </tr>

        </>
    );
}

const columnsHeader: TableColumn[] = [
        { label: "" },
        { label: "Fecha de emisión" },
        { label: "Serie - Número" },
        { label: "Nombre/Razón social del proveedor" },
        { label: "Total IGV" },
        {
            label: "Observación",
            tooltip: "Al rechazar una factura estás de acuerdo con nuestro análisis y rechazas la factura. \n Al validar una factura, justificas el comprobante y lo transferimos a los gastos deducibles de la empresa."
        },
        { label: "" },
        { label: "" },
];

const steps: Step[] = [
    {
        title: "Valida o rechaza las facturas observadas",
        content:
            "Tú decides si las incluyes en el periodo seleccionado (Se reflejará en el formato RCE).",
        disableBeacon: true,
        placement: "top",
        target: ".actions",
    },
]

function TableByDiscrimination({ reportsObserved }: { reportsObserved: ReportsObserved }) {
    const [ isModalOpenShowItems, setModalOpenShowItems ] = useState<ModalState>(ModalState.CLOSE);
    const [selectedCpes, setSelectedCpes] = useState<Cpes | null>(null);
    
    const completedOnboardingTwo = localStorage.getItem('completedOnboardingTwo')
   
    const handleOnboardingCallback = ({ status }: CallBackProps) => {
        if (status === 'finished') {
            localStorage.setItem('completedOnboardingTwo', 'true')
        }
    }

    return (
        <div className="my-5">
            {!completedOnboardingTwo && (
                <Joyride
                    disableOverlay
                    tooltipComponent={TourTooltip}
                    styles={{
                        options: {
                            arrowColor: "#171717",
                        },
                    }}
                    steps={steps}
                    callback={handleOnboardingCallback}
                />
            )}
            <div className="grid grid-cols-table">
                <table className="max-w-full w-full text-sm text-left rtl:text-right text-gray-500">
                    <TableHeader columns={columnsHeader} cpes={reportsObserved.cpes} />
                    <tbody>
                        {reportsObserved.cpes.map((cpe: Cpes) => (
                                <TableRow
                                    key={cpe.code}
                                    cpes={cpe}
                                    reportsObserved={reportsObserved}
                                    setModalOpenShowItems={setModalOpenShowItems}
                                    setSelectedCpes={setSelectedCpes}
                                />
                        ))}
                    </tbody>
                </table>

                <ShowItemsModal
                    modalState={isModalOpenShowItems}
                    setModalState={setModalOpenShowItems}
                    closeModalCallback={() => setModalOpenShowItems(ModalState.CLOSE)}
                    cpes={selectedCpes}
                />
            </div>
        </div>
    );
}

export default TableByDiscrimination;
