import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatNumber } from "./helpers";
import { useDashboardStore } from "../store/DashboardStore";
import Card from "../shared/CardInfo";
import "./BillsObservedDetails.css";
import TableByDiscrimination from "./TableByDiscrimination";
import Modal, { ModalState } from "../shared/Modal";
import exclamationIcon from "/assets/ic_exclamation_triangle.svg?url";
import FilterByPeriod from './FilterByPeriod.tsx';
import icLeftGray from "/assets/ic_angle_left_gray.svg?url";

interface AprobeReportsModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    cpesSelected: {
        code: string;
        reason: string;
    }[]
}

const CardsHeader = ({ reportsObserved }: { reportsObserved: ReportsObserved }) => {
    const dataCards = [
        {
            ccsClass: 'bill-obs__card--1',
            label: 'Monto total de IGV',
            amount: reportsObserved.total_igv_amount ?? 0,
            total: null
        },
        {
            ccsClass: 'bill-obs__card--2',
            label: 'Total de observaciones',
            amount: null,
            total: reportsObserved.total_count ?? 0,
        }
    ];

    return (
        <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-xl">
            {dataCards.map((data, index) => (
                <Card key={`card-bill-observed-${index}`} className={`${data.ccsClass} h-full flex-[1_1_100%]`}>
                    <Card.Body>
                        <p className="font-semibold text-sm f-secondary mb-2">{ data.label }</p>

                        { data.amount || data.amount === 0 ? (
                            <p className="text-xl f-secondary font-normal f-secondary">s/ { formatNumber(data.amount) }</p>
                        ) : (
                            <p className="text-xl f-secondary font-normal f-secondary">{ data.total }</p>
                        ) }
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

// Cuando selecciona muchos reportes
const AprobeRepostsModal = ({modalState, setModalState, closeModalCallback, cpesSelected}: AprobeReportsModalProps) => {
    const { declineReportObserved, fetchDashboardReportsObserved } = useDashboardStore(state => state);

    const onClickAprobeReports = () => {
        setModalState(ModalState.LOADING);

        declineReportObserved(cpesSelected)
            .then(() => {
                
                fetchDashboardReportsObserved()
                    .then(() => console.info("Reports observed fetched"))
                    .catch((_) => {
                        console.error("Reports observed data couldn't fetch");
                    });
                console.log("Correct report success");
                setModalState(ModalState.DONE);
            })
            .catch((_) => {
                setModalState(ModalState.ERROR);
                console.error("Correct report error")
            });
    }
    
    return (
        <Modal modalState={modalState} typeModal="aprobeReport" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="text-center">
                    <img className="inline-block" src={exclamationIcon} alt="Alerta" />
                    <h2 className="text-xl my-2 leading-7 font-medium">¿Estás de acuerdo con las observaciones?</h2>
                </div>
                <div className="">
                    <p className="text-sm mb-3">
                        Los comprobantes ya no se contarán como deducibles, y no se sumarán para el cálculo de compras
                        del periodo. No podrás deshacer esta opción.
                    </p>
                    <div className="sm:flex gap-3 items-center justify-center">
                        <button
                            className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[134px] px-3 py-2 bg-stone-950 text-white"
                            onClick={() => onClickAprobeReports()}
                        >Aprobar todos</button>
                        <button
                            className="flex-none rounded-[20px] w-full sm:w-[78px] px-3 py-2 border border-stone-950"
                            onClick={() => closeModalCallback()}
                        >Volver</button>
                    </div>
                </div>           
            </div>
        </Modal>
    );
}

function BillsObservedDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { ruc, businessName } = location.state || {};
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [isModalOpenAprobeReports, setModalOpenAprobeReports] = useState<ModalState>(ModalState.CLOSE);
    const filters = useDashboardStore((state) => state.filters);
    const fetchDashboardReportsObserved = useDashboardStore((state) => state.fetchDashboardReportsObserved);
    const { reportsObserved, setReportsObserved } = useDashboardStore(state => state);
    const { tax_period } = useDashboardStore(state => state.valuesFilterSelected);

    useEffect(() => {
        if (!ruc || !businessName || !tax_period) {
            navigate("/inicio");
        }

        setSelectedPeriod(tax_period);
        fetchDashboardReports();
    }, [navigate, ruc, businessName]);

    const fetchDashboardReports = () => {
        fetchDashboardReportsObserved()
            .then(() => console.info("Dashboard reports fetched"))
            .catch((_) => console.error("Dashboard reports couldn't fetch"));
    };

    const aprobeReports = () => {
        setModalOpenAprobeReports(ModalState.OPEN);
    }

    const setCpesSelected = () => {
        const cpesSelected = reportsObserved?.cpes.filter(cpes => cpes.checked);
        return cpesSelected && cpesSelected.length > 0 ? cpesSelected.map(cpes => ({
            code: cpes.code,
            reason: ''
        })) : []
    }

    const deselectReports = () => {
        if (reportsObserved) {
            const updateCpes = reportsObserved.cpes.map(cpesItem => ({
                ...cpesItem,
                checked: false
            }));
    
            setReportsObserved({
                ...reportsObserved,
                cpes: updateCpes
            });
        }
    }

    return (
        <div>
            <Link to="/inicio" className="flex items-center gap-1">
                <img src={icLeftGray} alt="Volver"/>
                <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
            </Link>
            <div className="min-[360px]:flex items-center gap-3 justify-between mt-4 lg:mt-6">
                <h2 className="bill-obs-detail__title">Observaciones</h2>
                <FilterByPeriod
                    filters={filters}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                    fetchDataForTable={fetchDashboardReports}
                />
            </div>

            {
                reportsObserved &&
                <>
                    <CardsHeader reportsObserved={reportsObserved} />
                    {
                        reportsObserved.cpes.filter(cpes => cpes.checked).length > 1 &&
                            (
                                <div className="flex items-center gap-4 my-4">
                                    <div className="flex items-center gap-3">
                                        <div className="cursor-pointer" onClick={() => deselectReports()}>
                                            <svg className="w-6 h-6" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM12.1667 8.83333H3.83333V7.16667H12.1667V8.83333Z" fill="#15161C"/>
                                            </svg>
                                        </div>
                                        <p className="text-sm">Deseleccionar</p>
                                    </div>
                                    <button
                                        onClick={() => aprobeReports()}
                                        className="bg-error-light rounded-[20px] px-3 py-2 font- font-xs text-error-dark"
                                    >Rechazar todo</button>

                                    <AprobeRepostsModal
                                        modalState={isModalOpenAprobeReports}
                                        setModalState={setModalOpenAprobeReports}
                                        closeModalCallback={() => setModalOpenAprobeReports(ModalState.CLOSE)}
                                        cpesSelected={setCpesSelected()}
                                    />
                                </div>
                            )
                    }
                    {
                        reportsObserved?.cpes.length > 0
                        ? <TableByDiscrimination
                            reportsObserved={reportsObserved}
                        />
                        : <p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
                    }                        
                </>
            }
        </div>
    );
}

export default BillsObservedDetails;
