import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthStore } from "../store/AuthStore";
import ErrorAlert from "../errors/ErrorAlert";
import CustomInput from "./components/CustomInput";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Ingrese su correo electrónico").email("Ingrese un correo válido"),
    password: Yup.string().required("Ingrese su contraseña"),
});

function LoginForm() {
    const navigate = useNavigate();
    const errorMessages: ErrorMessage[] = useAuthStore((state) => state.errorMessages);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginCredentials>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    });

    const setUser = useAuthStore((state) => state.setLoginRequest);
    const loginUser = useAuthStore((state) => state.login);

    const onSubmit = (data: LoginCredentials) => {
        setUser({ ...data });
        loginUser()
            .then(() => {
                eventGa4Service({
                    action: CONTAPRO_ANALYTICS.AUTH.LOGIN_PAGE.BUTTON_LOGIN.ACTION
                });
                localStorage.setItem("isFirstLogin", "false");
                localStorage.setItem("isFirstViewDashboard", "true");
                navigate("/inicio");
            })
            .catch((_) => console.error("Error getting user data..."));
    };

    const handleClickLinkForgotPassword = () => {
        eventGa4Service({
            action: CONTAPRO_ANALYTICS.AUTH.LOGIN_PAGE.BUTTON_FORGOT_PSW.ACTION
        });
    };

    const handleClickLinkCreateAccount = () => {
        eventGa4Service({
            action: CONTAPRO_ANALYTICS.AUTH.LOGIN_PAGE.BUTTON_NEW_ACCOUNT.ACTION
        });
    }

    return (
        <div className="container">
            <ErrorAlert errorMessages={errorMessages} />

            <form
                action="#"
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
                className="px-5 mt-5 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray block"
            >
                <div className="text-center">
                    <h1 className="text-2xl font-bold mb-1">Inicia sesión</h1>
                    <p className="text-sm mb-3">Gestiona la información de tus empresas.</p>
                </div>
                <CustomInput<LoginCredentials>
                    id="email"
                    labelInput="Usuario"
                    type="text"
                    placeholder="user@gmail.com"
                    register={register}
                    errorMessage={errors.email?.message}
                />

                <CustomInput<LoginCredentials>
                    id="password"
                    labelInput="Contraseña"
                    type="password"
                    placeholder="Contraseña"
                    register={register}
                    errorMessage={errors.password?.message}
                />

                <div className="mt-5">
                    <button type="submit" className="w-full primary-button-2 py-3 px-4">
                        Iniciar sesión
                    </button>
                </div>

                <div className="mt-5 flex flex-col text-center">
                    <Link
                        to="/recuperar-contraseña-identificacion"
                        className=" text-[#5D6075] text-center"
                        onClick={() => handleClickLinkForgotPassword()}
                    >
                        ¿Olvidaste tu contraseña?
                    </Link>
                    <Link
                        to="/crear-cuenta"
                        className="font-semibold inline-block mt-5 sm:hidden text-[#1B68FF] text-center"
                        onClick={() => handleClickLinkCreateAccount()}
                    >
                        ¿No tienes cuenta? Regístrate
                    </Link>
                </div>
            </form>

            <div className="hidden sm:block mt-8 text-center">
            <Link
                to="/crear-cuenta"
                className="font-semibold text-[#1B68FF] text-center"
                onClick={() => handleClickLinkCreateAccount()}
            >
                ¿No tienes cuenta? Regístrate
            </Link>
            </div>
        </div>
    );
}

export default LoginForm;
