import { useNavigate } from "react-router-dom";
import { useDashboardStore } from "../store/DashboardStore";
import { useAuthStore } from "../store/AuthStore.tsx";
import { useEffect, useState } from "react";
import Modal, { ModalState } from "../shared/Modal.tsx";
import PlanTypeAlert from "./PlanTypeAlert.tsx";
import ModalVideoTutorial from "./ModalVideoTutorial.tsx";
import emptyStateBusinessImg from "/assets/ic_business-list-close.svg?url";
import iconQuestionBrown from "/assets/ic_question--brown.svg?url";
import iconClockModal from "/assets/ic_clock_close_d.svg?url";
import iconPlus from "/assets/ic_plus_circle_white.svg?url";
import iconPadlockModal from "/assets/ic_padlock_shield_close_d.svg?url";
import { eventGa4Service } from "../ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "../shared/analytics.tsx";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import "../Home.css";
import { useBusinessStore } from "@/store/BusinessStore.tsx";

enum statusBusiness {
    inProgress = 0,
    available = 1,
}

interface HeaderHomeProps {
    name: string;
    setSearching: (searching: string) => void;
    handleButtonRegister: () => void;
}

interface CardProps {
    index: number;
    user: User;
    business: BusinessFilter;
    onClickDetails: (ruc: string, businessName: string, business?: BusinessFilter) => void;
    setBusinessSelected: (business: BusinessFilter) => void;
    setIsModalDataInProcessOpen: (state: ModalState) => void;
    onClickProcessData?: () => void;
    onClickErrorFetchingData?: () => void;
}

interface BusinessStatusProps {
    status: number;
    register_date: string;
    handleClickInStatus: () => void;
}

enum contaproSubscription {
    free = 1,
    pay = 2,
}

interface StatusDataModal {
    businessSelected: BusinessFilter;
    modalState: ModalState;
    closeModalCallback: () => void;
}

const setBackgroundCardIcon = (index: number, userSubscribed: boolean) => {
    if (userSubscribed) {
        return index % 2 === 0 ? "db__card-icon--1" : "db__card-icon--2";
    }

    return "db-card-icon--disabled";
};

const setBaseClassCardBusiness = (index: number, userSubscribed: boolean) => {
    if (userSubscribed) {
        return index % 2 === 0 ? "db__card--1" : "db__card--2";
    }

    return "db__card--disabled";
}

const setCursorPointerClass = (business: BusinessFilter, userSubscribed: boolean) => {
    if (!userSubscribed) {
        return "";
    }

    return business.status === statusBusiness.available ? "cursor-pointer" : "";
};

const setHoverClass = (business: BusinessFilter, userSubscribed: boolean) => {
    return business.status === statusBusiness.available && userSubscribed ? "db__card--hover" : "";
};

const calculateDaysPassed = (daysPassed: string) => {
    const daysPassedToDate = new Date(daysPassed);
    const actualDate = new Date();

    const differenceMs = actualDate.getTime() - daysPassedToDate.getTime();
    return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
};

const BusinessStatus = ({ status, register_date, handleClickInStatus }: BusinessStatusProps) => {
    const daysPassed = calculateDaysPassed(register_date);

    if (status === statusBusiness.inProgress && daysPassed <= 2) {
        return <BusinessInProgress handleClick={handleClickInStatus} />;
    }

    if (status === statusBusiness.inProgress && daysPassed > 2) {
        return <BusinessErrorFetchingData handleClick={handleClickInStatus} />;
    }

    return null;
};

const HeaderHome = ({ name, setSearching, handleButtonRegister }: HeaderHomeProps) => {
    return (
        <div className="mb-6">
            <div className="md:flex justify-between mb-6">
                <div className="mb-4 md:mb-0">
                    <h2 className="home__title text-3xl font-bold mb-3">
                        Hola de nuevo, <span className={name ? "text-[#1B68FF] font-bold" : ""}>{name || "..."}</span>
                    </h2>
                    <h3 className="home__subtitle f-secondary">Gestiona la información de tus empresas</h3>
                </div>

                <div className="hidden md:block">
                    <button
                        onClick={handleButtonRegister}
                        className="btn__download bg-primary-black flex gap-2 rounded-[40px] text-white text-center px-8 py-3">
                        <span className="font-semibold text-base">Registrar nueva empresa</span>
                        <img src={iconPlus} alt="Nueva empresa" />
                    </button>
                </div>
            </div>
            <form className="w-[288px]">
                <div className="relative w-100">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20">
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="block w-full py-2.5 px-3 ps-10 text-sm text-gray-900 border border-[#C7CADD] rounded-lg bg-white focus:ring-gray-700 focus:border-gray-700 outline-none"
                        placeholder="Buscar empresa"
                        autoComplete="off"
                        onChange={(e) => {
                            setSearching(e.target.value);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

const BusinessErrorFetchingData = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <div className="db__card--error inline-flex gap-2 items-center mb-2">
            <svg
                className="flex-none"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 12 12"
                fill="none">
                <circle cx="6" cy="6" r="6" fill="#D92036" />
            </svg>
            <span>Error de accesos</span>
            <img
                onClick={handleClick}
                src={iconQuestionBrown}
                alt="Duda"
                className="hidden lg:inline-block lg:cursor-pointer"
            />
            <img src={iconQuestionBrown} alt="Duda" className="inline-block lg:hidden" />
        </div>
    );
};

const BusinessInProgress = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <div className="db__card--progress inline-flex gap-2 items-center mb-2">
            <svg
                className="flex-none"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 12 12"
                fill="none">
                <circle cx="6" cy="6" r="6" fill="#FA7800" />
            </svg>
            <span>Procesando datos</span>
            <img
                onClick={handleClick}
                src={iconQuestionBrown}
                alt="Duda"
                className="hidden lg:inline-block lg:cursor-pointer"
            />
            <img src={iconQuestionBrown} alt="Duda" className="inline-block lg:hidden" />
        </div>
    );
};

const CardBusiness = ({
    index,
    user,
    business,
    onClickDetails,
    setBusinessSelected,
    setIsModalDataInProcessOpen
}: CardProps) => {
    const classCssContainerCard = 'db__card rounded-[24px] px-5 py-4 flex items-center gap-6';
    const userSubscribed = user.subscription !== null;
    const onClickCard = () => {
        if (business.status === statusBusiness.inProgress) {
            setBusinessSelected(business);
            setIsModalDataInProcessOpen(ModalState.OPEN);
        }

        const businessAvailable = business.status === statusBusiness.available;
        return businessAvailable && userSubscribed ? onClickDetails(business.ruc, business.name, business) : null;
    };

    return (
        <div
            className={`${classCssContainerCard} ${setBaseClassCardBusiness(index, userSubscribed)} ${setCursorPointerClass(business, userSubscribed)} ${setHoverClass(
                business,
                userSubscribed,
            )}`}
            onClick={onClickCard}
        >
            <div className={`flex-none p-4 rounded-[58px] ${setBackgroundCardIcon(index, userSubscribed)}`}>
                <img src="/assets/ic_suitcase.svg" alt="Empresa" />
            </div>
            <div className="">
                <BusinessStatus
                    status={business.status}
                    register_date={business.register_date}
                    handleClickInStatus={() => {}}
                />
                <p className="db__card--title f-secondary">{business.name || business.ruc}</p>
            </div>
            <div className={`ml-auto ${business.status === statusBusiness.available && userSubscribed ? "block" : "hidden"}`}>
                <ChevronRightIcon className="w-6 h-6" />
            </div>
        </div>
    );
};

const LoadingText = () => {
    return (
        <div className="wrapper">
            <div className="loading-text">
                <h1>
                    Cargando empresas
                    <span className="dot-one"> .</span>
                    <span className="dot-two"> .</span>
                    <span className="dot-three"> .</span>
                </h1>
            </div>
        </div>
    );
};

const EmptyBusiness = () => {
    const navigate = useNavigate();

    const onClickRegisterBusiness = () => {
        eventGa4Service({
            action: CONTAPRO_ANALYTICS.HOME.MENU_BUSINESS_REGISTER.ACTION,
        });
        navigate("/registrar-empresa", {
            state: { newUser: true },
        });
    };

    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="flex-none text-center">
                <img className="inline-block mb-3" src={emptyStateBusinessImg} alt="No hay empresas" />
                <h2 className="font-bold text-2xl md:text-[28px] mb-3">No tienes empresas registradas</h2>
                <p className="font-sm md:font-base mb-3">Registra tu primera empresa</p>
                <button
                    className="bg-primary-black rounded-[20px] px-4 py-2 font-medium font-xs text-white"
                    onClick={() => onClickRegisterBusiness()}>
                    Registrar empresa
                </button>
            </div>
        </div>
    );
};

const ModalDataInProcess = ({ businessSelected, modalState, closeModalCallback }: StatusDataModal) => {
    const daysPassed = calculateDaysPassed(businessSelected?.register_date);

    const textStatusInProcess =
        "Los datos de tu empresa tardan hasta 2 días en procesarse. Si llevan más de 2 días en proceso puede deberse a un error de accesos al portal SOL. Actualiza tus credenciales comunicándote con contacto@somoscontapro.com.pe";
    const textStatusError =
        "Si tus datos tardan más de 2 días en procesarse, puede deberse a un error con tus credenciales del portal SOL. Actualízalas comunicándote con nosotros a contacto@somoscontapro.com.pe.";

    return (
        <Modal modalState={modalState} typeModal="generic" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="text-center">
                    <img
                        src={daysPassed <= 2 ? iconClockModal : iconPadlockModal}
                        alt="Icono"
                        className="inline-block"
                    />
                    <h2 className="text-xl my-2 leading-7 font-medium">
                        {daysPassed <= 2 ? "Datos en proceso" : "Error de credenciales"}
                    </h2>
                </div>
                <div className="text-center">
                    <p className="text-sm">{daysPassed <= 2 ? textStatusInProcess : textStatusError}</p>
                </div>
            </div>
        </Modal>
    );
};

function Dashboard() {
    const navigate = useNavigate();
    const [businessSelected, setBusinessSelected] = useState<BusinessFilter | null>(null);
    const [isModalDataInProcessOpen, setIsModalDataInProcessOpen] = useState<ModalState>(ModalState.CLOSE);
    const [isEmptyBusiness, setIsEmptyBusiness] = useState(false);
    const [isShowModalRegisterSuccess, setIsShowModalRegisterSuccess] = useState(false);
    const [searching, setSearching] = useState("");
    const {
        filters,
        setValuesFilterSelected,
        fetchFilters,
        resetReportsPerceptions,
        resetReportsObserved,
        resetDashboardData,
    } = useDashboardStore((state) => state);
    const { user } = useAuthStore((state) => state);
    const setBusiness = useBusinessStore(state => state.setBusiness)

    const userHasFreeSubscription = user.subscription?.plan_type === contaproSubscription.free;
    const userPlanType = userHasFreeSubscription ? 'free': 'expired_plan'

    // se crea variable para filtrar las empresas por nombre
    const filteredBusinesses = filters?.businesses.filter((business) =>
        business.name.toLowerCase().includes(searching.toLowerCase()),
    );

    const handleButtonRegister = () => {
        eventGa4Service({
            action: CONTAPRO_ANALYTICS.HOME.MENU_BUSINESS_REGISTER.ACTION,
        });
        navigate("/registrar-empresa");
    };

    const onClickDetails = (ruc: string, businessName: string, business?: BusinessFilter) => {
        if (!ruc || !businessName) {
            return;
        }
        if (business) {
            setBusiness({
                uuid: business.uuid,
                name: business.name,
                ruc: business.ruc,
                privacy: false,
                registered: false,
                terms: false
            })
        }
        const initialSelectedTaxPeriod =
            filters.tax_periods && filters.tax_periods.length > 0 ? filters.tax_periods[0] : null;

        setValuesFilterSelected({
            ruc,
            tax_period: initialSelectedTaxPeriod,
        });

        eventGa4Service({
            action: CONTAPRO_ANALYTICS.HOME.CARD_BUSINESS.ACTION,
        });

        navigate("/dashboard", {
            state: {
                ruc,
                businessName,
            },
        });
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Verificar si es la primera vez que hace login
        const isFirstLogin = localStorage.getItem("isFirstLogin");

        if (isFirstLogin && isFirstLogin === "true") {
            setIsShowModalRegisterSuccess(true);
        }

        resetReportsObserved();
        resetReportsPerceptions();
        resetDashboardData();

        fetchFilters(signal)
            .then((response) => {
                setIsEmptyBusiness(false);
                if (response && response.businesses.length === 0) {
                    console.log("No businesses");
                    setIsEmptyBusiness(true);
                }
            })
            .catch((error) => {
                if (error.statusText !== "canceled") {
                    console.error("Filters couldn't fetch");
                }
            });

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <div className="px-6 py-8">
            {!isEmptyBusiness && (
                <HeaderHome
                    name={user.name}
                    setSearching={setSearching}
                    handleButtonRegister={handleButtonRegister}
                />
            )}
            {filters.businesses?.length > 0 ? (
                <>
                     {(userHasFreeSubscription || !user.subscription) && (
                        <PlanTypeAlert planType={userPlanType} endDate={user.subscription?.end_date} />
                     )}   
                    <div className="grid gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mt-6 mb-16 lg:mb-0">
                        {filteredBusinesses
                            .sort((a, b) => (a.status > b.status ? -1 : 1))
                            .map((business: BusinessFilter, i: number) => (
                                <div key={ `card_${ i }` }>
                                    <CardBusiness
                                        index={ i }
                                        user={user}
                                        business={ business }
                                        onClickDetails={ onClickDetails }
                                        setBusinessSelected={ setBusinessSelected }
                                        setIsModalDataInProcessOpen={ setIsModalDataInProcessOpen }
                                    />
                                </div>
                            ))}
                    </div>

                    <div className="block mt-6 md:hidden fixed bottom-0 left-0 h-auto bg-white w-full p-4">
                        <button
                            onClick={handleButtonRegister}
                            className="btn__download bg-primary-black flex gap-2 rounded-[40px] text-white text-center px-8 py-3">
                            <span className="font-semibold text-base">Registrar nueva empresa</span>
                            <img src={iconPlus} alt="Nueva empresa" />
                        </button>
                    </div>
                </>
            ) : (
                !isEmptyBusiness && <LoadingText></LoadingText>
            )}

            {isEmptyBusiness && <EmptyBusiness />}

            {businessSelected && (
                <ModalDataInProcess
                    businessSelected={businessSelected}
                    modalState={isModalDataInProcessOpen}
                    closeModalCallback={() => setIsModalDataInProcessOpen(ModalState.CLOSE)}
                />
            )}

            {isShowModalRegisterSuccess && (
                <ModalVideoTutorial modalType="registerSuccess" showModal={isShowModalRegisterSuccess} />
            )}
        </div>
    );
}

export default Dashboard;